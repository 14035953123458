.it-footer {
  &-main {
    background-color: $primary-dark;
    padding-inline: 2rem;
    &_footer-logo {
      max-width: 16rem;
    }

    h4 a {
      font-size: $font-size-medium;
    }

    section {
      padding-inline: unset;
      padding-block: 2rem;
      @media (max-width: #{map-get($grid-breakpoints,sm)}) {
        & > div.contacts {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }
      }
      @media (min-width: #{map-get($grid-breakpoints,md)}) {
        padding-block: 3rem;
      }
    }

    .link-list-wrapper {
      ul li a {
        text-decoration: underline;
        font-size: $font-size-regular;
      }
    }

    .footer-items-col {
      h3 {
        font-size: $font-size-xlarge !important;
        font-weight: 700;
        margin-block-end: 1rem;
        a {
          color: $white;
          transition: all 0.3s ease-in-out;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            scale: 1.05;
          }
        }
      }
      & > div > ul > li {
        a {
          transition: all 0.3s ease-in-out;
          color: $white;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            scale: 1.05;
          }
        }
      }
    }

    .footer-contacts-col {
      p {
        font-size: $font-size-large !important;
        a {
          text-decoration: underline;
          color: $white;
        }
      }
      h3 {
        font-size: $font-size-xlarge !important;
        font-weight: 700;
        margin-block-end: 1rem;
        > a {
          color: $white;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footer-social-col {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-block-end: 1px solid $white;
      text-align: left !important;
      padding-block-end: 1rem;
      h3 {
        font-size: $font-size-xlarge !important;
        font-weight: 700;
        margin-block-end: 1rem;
        > a {
          color: $white;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      svg {
        transition: all 0.3s ease-in-out;
        &:hover {
          scale: 1.1;
          background-color: $white !important;
          fill: $dark-blue !important;
          border-radius: 0.25rem;
        }
      }
    }
  }

  &-small-prints {
    background-color: $darker-blue;
    a {
      font-weight: 700;
      font-size: $font-size-small;
    }

    ul.it-footer-small-prints-list {
      li {
        padding: unset;
        margin-inline-end: 1rem;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,sm)}) {
    .footer-contacts-col {
      border: none;
      margin-block-end: unset;
    }

    .footer-social-col {
      border: none;
      margin-block: unset;
      text-align: center !important;
    }
  }
}
