@use './mixins.module' as mixins;
$card-border-color: $teal;
$text-color: $black;
$author-color: $blueish-gray;
$number-color: $dark-gray;
$title-color: $dark-blue;
$title-dark-color: $light-black;

.card-wrapper {
  height: 100%;
  max-height: 20rem;
  padding-block-end: 0;
  aspect-ratio: 3.5/4;
  margin: auto;
  width: 100%;

  &:has(> .minimal) {
    max-height: 18rem;
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    padding-bottom: 1rem;
    aspect-ratio: 9/12;
    max-height: 25rem;
  }
}

// BlockquoteCard
.blockquote-card {
  &_container {
    max-width: 22rem;
    height: 100%;
    &.card {
      &:after {
        margin: unset;
      }
    }
    border-left: 0.5rem solid $card-border-color;

    &_body {
      padding-inline: 2.5rem;
      padding-block: 2rem;
      max-width: 22rem;
      min-height: 11rem;
      display: grid;
      grid-template-rows: 1fr 1.25rem;
      align-content: center;
      gap: 0.5rem;

      &_quote {
        font-size: $font-size-medium;
        line-height: 1.5rem;
        font-style: italic;
        font-weight: 400;
        color: $text-color;
        margin: unset;
        max-width: 90%;
        align-self: center;
        word-break: break-word;
      }

      &_author {
        font-size: $font-size-medium;
        line-height: 1.5rem;
        font-weight: 400;
        color: $author-color;
        font-style: italic;
        margin: unset;
      }
    }
  }
}

// LearnMoreCard
.learn-more-card {
  &_section {
    background-color: white;
    border-radius: 0.25rem;
    padding: 2rem;
    cursor: pointer;

    &_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_number {
        > span {
          font-size: $font-size-large;
          line-height: 1.5rem;
          font-weight: 600;
          color: $number-color;
        }
        margin: unset;
        margin-block-end: 0.5rem;
      }

      &_title {
        > h3 {
          font-size: $font-size-large;
          line-height: 1.5rem;
          font-weight: 600;
          color: $title-color;
          margin: unset;
        }
        margin: unset;
        margin-block-end: 0.5rem;
      }

      &_text {
        font-size: $font-size-regular;
        line-height: 1.5rem;
        font-weight: 400;
        color: $text-color;
        margin: unset;
        margin-block-end: 0.5rem;
        word-break: break-word;
      }

      &_cta {
        font-size: $font-size-small !important;
        line-height: 1rem !important;
        font-weight: 700;
        margin: unset;
        margin-block-start: 1.5rem;
        min-width: 9.5rem;
        text-transform: uppercase;
        > svg {
          margin-inline-start: 0.5rem;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      &_container {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 1.5rem;
        &_number {
          margin: unset;
          > span {
            font-size: $font-size-xlarge;
            line-height: 1.75rem;
          }
        }
        &_title {
          margin: unset;
          margin-inline-end: auto;
          > h3 {
            font-size: $font-size-xlarge;
            line-height: 1.75rem;
          }
        }
        &_text {
          margin: unset;
          word-wrap: break-word;
        }
        &_cta {
          margin: unset;
        }
      }
    }
  }
}

// EvidenceCard
.evidence-card {
  &_section {
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-radius: 0.25rem;
    &_container {
      display: flex;
      flex-direction: column;
      background-color: $white;
      height: 100%;
      border-radius: 0.25rem;
      overflow: hidden;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: 1.5rem;
        padding: 1.5rem;
        width: 100%;
        height: 100%;

        &_top {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
        }

        &_text {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          &_title {
            color: $title-color;
            text-align: left;
            font-size: $font-size-large;
            line-height: 1.5rem;
            margin-bottom: unset;
            font-weight: 600;
          }

          &_description {
            color: $text-color;
            font-size: $font-size-regular;
            line-height: 1.5rem;
            margin-bottom: unset;
            font-weight: 400;
          }
        }

        &_button,
        .btn.btn-link {
          color: $teal-medium-2;
          font-size: $font-size-small;
          font-weight: 700;
          line-height: 1rem;

          svg {
            margin-inline-start: 0.5rem;
          }
        }
      }

      &_image {
        position: relative;
        width: 100%;
        max-height: 30%;
        min-height: 7rem;
        > img {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      flex-shrink: 0;
      &_container {
        flex-direction: row;
        &_paragraph {
          max-height: unset;
          max-width: 50%;
          padding: 2rem;
          gap: 1rem;
          justify-content: space-evenly;
          &_text {
            gap: 1.5rem;
            &_title {
              font-size: $font-size-xxlarge;
              font-weight: 700;
              line-height: 2.5rem;
            }
            &_description {
              font-size: $font-size-regular;
              line-height: 1.5rem;
            }
          }
          &_button,
          .btn.btn-link {
            align-self: flex-end;
          }
        }
        &_image {
          max-height: 100%;
          max-width: 50%;
        }
      }
    }
  }
}

// ProjectCard
.project-card {
  &_section {
    transition: all 0.3s ease-in-out;
    &_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $white;
      border-radius: 0.25rem;
      padding-inline: 1rem;
      padding-block: 1rem;
      // padding-block: 4rem 1rem;
      gap: 1.5rem;
      outline: 2px solid $white;
      transition: all 0.2s ease-in-out;

      &_wrapper {
        display: flex;
        flex: 1;
        justify-items: flex-end;
        align-items: flex-end;
      }

      &_title {
        font-size: $font-size-xlarge;
        font-weight: 700;
        line-height: 2rem;
        margin: unset;
        color: $title-color;

        text-overflow: ellipsis;
        overflow: hidden;
      }

      &_description {
        font-size: $font-size-regular;
        font-weight: 400;
        line-height: 1.5rem;
        color: $lighter-blueish-gray;
        margin: unset;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        word-break: break-word;
        text-wrap: pretty;
      }

      &_cta {
        font-size: $font-size-regular;
        font-weight: 700;
        line-height: 1.5rem;
      }
    }

    &:hover {
      .project-card_section {
        &_container {
          outline: 2px solid $teal-medium-2;

          &_title {
            color: $title-dark-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, sm)}) {
    .project-card_section_container {
      min-width: 21.5rem;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        padding-inline: 1rem 3rem;
        padding-block: 1rem;
        // padding-block: 6rem 1rem;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
          padding-inline: 2rem 5rem;
          padding-block: 2rem;
          // padding-block: 10rem 2rem;
          height: 28rem;
          max-width: 26.1875rem;
        }

        &_title {
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          white-space: nowrap;
        }

        &_cta {
          font-size: $font-size-medium;
          line-height: 1.75rem;
        }
      }
    }
  }
}

// DynamicCard
.dynamic-card {
  &_container {
    background-color: $white;
    border-radius: 0.25rem;
    overflow: hidden;
    outline: 3px solid transparent;
    margin-block-start: 2px;
    transition: all 0.2s ease-in-out;
    min-height: 10rem;
    max-width: calc(100% - 1rem);
    margin-inline: auto;

    &#card-container::after {
      margin: unset;
    }

    &:hover {
      outline: 2px solid $teal-medium-2;
    }

    &_image {
      height: 15rem;
      position: relative;
      transition: all 0.3s ease;

      > img {
        object-fit: cover;
        object-position: center;
      }
    }

    &_body {
      min-height: 12rem;
      height: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &_tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 1rem;
        gap: 1rem;
      }

      &_button {
        width: fit-content;
        margin: auto;
      }

      &:hover & {
        &_tag_content {
          background-color: $teal-medium-2;
        }

        &_paragraph_title {
          text-decoration-line: underline;
          color: $light-black;
        }

        &_button {
          color: $teal-dark;
          border-color: $teal-dark;
        }
      }

      &_paragraph {
        flex: 1;
        margin-inline-end: 1rem;

        & > a {
          text-decoration: none;
        }

        &_title {
          color: $title-color;
          text-align: left;
          font-size: $font-size-xlarge !important;
          line-height: 2rem !important;
          letter-spacing: -1px;
          margin-bottom: 0 auto;
          font-weight: 600;
          @include mixins.text-shorten(3);
        }

        &_description {
          color: $text-color;
          font-size: $font-size-regular;
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_container {
      min-height: 25rem;
      &.minimal {
        min-height: unset;
        height: 100%;
      }
      &_body {
        max-height: unset;
        min-height: unset;
        padding: 1.5rem;
        &_paragraph {
          &_title {
            font-size: $font-size-xlarge;
            line-height: 2rem;
            font-weight: 600;
            // margin-block-start: 1.5rem;
          }

          &_description {
            font-size: $font-size-regular;
            font-weight: 400;
            margin-block: 1rem 1.5rem;
          }
        }
      }
    }
  }
}

.investment-card {
  &_container {

    border-radius: 4px;
    background-color: $white;
    padding: 1rem;
    width: 100%;
    height: auto;

    &::after{
      display: none;
    }

    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      height: 160px !important;
      width: 200px;

      &::after{
        display: block;
      }
    }

    &_header {
      margin-bottom: .5rem;
      span {
        display: block;
        font-size: $font-size-regular !important;
        line-height: 1rem;
        font-weight: 400;
        color: $text-color;
        margin-bottom: 1rem;
      }
      &_title {
        font-size: $font-size-large;
        line-height: 1.5rem;
        font-weight: 600;
      }

      @media (min-width: #{map-get($grid-breakpoints, sm)}) {
        margin-bottom: 0;

        span{
          margin-bottom: 0;
        }
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 !important;


      &_cta {
        position: static !important;
        font-size: $font-size-small !important;
        line-height: 1 !important;
        font-weight: 700;
        color: $teal-medium-1;

        @media (min-width: #{map-get($grid-breakpoints, sm)}) {
          position: absolute !important;
          bottom: 16px !important;
        }

        span{
          margin-right: 0 !important;
          line-height: 1 !important;
          font-size: $font-size-small !important;
        }

        .icon{
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.progress-card {
  &_container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    background-color: transparent;
    --bs-card-height: auto;
    &::after{
      display: none;
    }

    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      width: clamp(200px, 100%, 280px);
    }

    &_title{
      color: $dark-blue;
      font-size: $font-size-large;
      line-height: 1.5rem;
      font-weight: 600;
      margin: 0;
    }

    &_body{
      height: 235px;
      border-radius: 4px;
      background-color: $white;
      padding: 1rem !important;
      width: 100%;
      border-radius: 4px;
      border: 2px solid $dark-blue;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      &_preheader{
        display: flex;
        align-items: center;
        gap: 1rem;

        span {
          color: $dark-blue;
          font-size: $font-size-regular !important;
        line-height: 1.5rem !important;
          font-weight: 600;
          margin: 0;
        }
      }

      &_content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;

        &_text{
          font-size: $font-size-regular !important;
          line-height: 1.5rem !important;
          font-weight: 400;
          color: $dark-blue;
        }
      }
    }

    &_header {
      span {
        font-size: $font-size-regular !important;
        line-height: 1rem;
        font-weight: 400;
        color: $text-color;
      }
      &_title {
        font-size: $font-size-large;
        line-height: 1.5rem;
        font-weight: 600;
      }
    }
  }

  &_progress{
    width: 100%;
    &_details{
      display: flex;
      align-items: flex-end;
      margin-bottom: .5rem;
      span{
        padding-inline-end: .25rem !important;
        font-weight: 400;
        font-size: $font-size-large !important;
        line-height: 1.5rem !important;
        color: $dark-gray;

        &.target{
          font-size: $font-size-regular !important;
        }
      }
    }

    &_bar{
      width: 100%;
      height: 2px;
      background-color: $lighter-gray;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      z-index: 0;
      &_fill{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #0066CC;
      }
    }
  }
}
