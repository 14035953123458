.fade-in,
.fade-out,
.slide-bottom-up {
  will-change: opacity, transform;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.fade-in-1 {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-in-2 {
  animation: fadeIn 0.7s ease-in-out;
}

.fade-in-3 {
  animation: fadeIn 0.9s ease-in-out;
}

.fade-in-4 {
  animation: fadeIn 1.1s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.3s ease-in-out;
}

.fade-out-4 {
  animation: fadeOut 1.1s ease-in-out;
}

.slide-bottom-up {
  animation: slideBottomUp 0.3s ease-in-out;
}

.slide-bottom-up-1 {
  animation: slideBottomUp 0.5s ease-in-out;
}

.slide-bottom-up-2 {
  animation: slideBottomUp 0.7s ease-in-out;
}

.slide-bottom-up-3 {
  animation: slideBottomUp 0.9s ease-in-out;
}

.slide-bottom-up-4 {
  animation: slideBottomUp 1.1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideBottomUp {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideLeftToRight {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rotate90 {
  0% {
    opacity: 0;
    transform: rotate(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

// Media query per dispositivi con prestazioni ridotte
@media (prefers-reduced-motion: reduce) {
  .fade-in,
  .fade-out,
  .slide-bottom-up {
    animation: none;
  }
}
