.richtext {
  &-default {
    color: $brownish-gray;
    font-size: $font-size-medium;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    & > * {
      font-size: $font-size-medium;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      font-size: $font-size-large;
      line-height: 2rem;
      & > * {
        font-size: $font-size-large;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img {
      word-break: break-all;
      max-width: 100%;
      height: auto;
    }
  }
}
