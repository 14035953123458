$button-bg: $dark-blue;
$button-bg-dark: hsl(178, 33%, 49%);
$button-bg-disabled: $lighter-beige;
$button-bg-disabled-dark: hsl(211, 21%, 36%);
$button-color-disabled: $blueish-gray;
$background-dark: $dark-blue;
$background-dark-beige: $dark-beige;

// Slider (Base class)
.swiper {
  grid-area: slider;
  width: 95%;
  margin-inline: auto;
  overflow: visible !important;
  padding-block-end: 2rem;
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    overflow: hidden !important;
  }

  &-button {
    &-disabled {
      opacity: 0;
    }
  }

  &-custom {
    &-wrapper {
      position: relative;
      width: calc(100% + 2rem);
      display: flex;
      margin-inline: auto;
      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        display: grid;
        grid-template-columns: 3rem 1fr 3rem;
        grid-template-rows: 1fr;
        grid-template-areas: 'buttonL slider buttonR';
        overflow: hidden;
      }

      &-only-child {
        grid-area: slider;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 1rem);
        height: 100%;
      }
    }
    &-button-prev,
    &-button-next {
      display: flex;
      padding: 0.75rem;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.25rem;
      z-index: 4;
      margin: auto;
      transition: all 0.3s ease-in-out;
      > svg {
        scale: 1.3;
      }

      &:hover {
        cursor: pointer;
        scale: 1.05;
        filter: brightness(0.9);
      }

      &.light {
        background-color: $button-bg;
        &.disabled {
          background-color: $button-bg-disabled;
          cursor: not-allowed;
          > svg > g > path {
            fill: $button-color-disabled;
          }
        }
      }
      &.dark {
        background-color: $button-bg-dark;
        &.disabled {
          background-color: $button-bg-disabled-dark;
          cursor: not-allowed;
          > svg > g > path {
            fill: $button-color-disabled;
          }
        }
      }
    }
    &-button-prev {
      grid-area: buttonL;
    }
    &-button-next {
      grid-area: buttonR;
    }

    &-light {
      --swiper-pagination-bottom: 0;
      --swiper-pagination-bullet-horizontal-gap: 0.5rem;
      --swiper-pagination-color: hsl(178, 33%, 49%);
    }
    &-dark {
      --swiper-pagination-bullet-horizontal-gap: 0.5rem;
      --swiper-pagination-bottom: 0;
      --swiper-pagination-color: white;
    }
  }

  &-wrapper {
    height: 100%;
    margin: auto;
  }

  &-slide {
    display: flex;
    align-items: stretch;
    width: fit-content;
    margin: auto;
    margin-right: auto;
  }
}

// Blockquote
.blockquote {
  &_section {
    padding-inline: $mobile-inline-spacing;
    padding-block: $mobile-block-spacing;
    background-color: $background-dark-beige;
    overflow: hidden;

    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      max-width: 87.5rem;
      margin: auto;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        &_title {
          color: $title-color;
          text-align: center;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          margin-bottom: unset;
          font-weight: 700;
        }

        &_description {
          color: $text-color;
          text-align: center;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
          word-break: break-word;
        }
      }

      &_slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;

      &_container {
        gap: 3.5rem;
        &_paragraph {
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }

          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }
      }
    }
  }
}

// CardsSlider
.cards-slider {
  &_section {
    padding-inline: $mobile-block-spacing;
    padding-block: $mobile-block-spacing;
    background-color: $background-dark;

    &.light {
      background-color: $background-light;
      .cards-slider_section_container {
        &_paragraph {
          &_title {
            color: $title-color;
          }
          &_description {
            color: $text-color;
          }
        }
      }
    }
    overflow: hidden;
    &_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 87.5rem;
      margin-inline: auto;
      height: 100%;
      gap: 2rem;
      &_paragraph {
        &_title {
          color: $white;
          text-align: left;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          margin-bottom: unset;
          font-weight: 700;
        }
      }
      &_slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-inline: auto;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
      &_container {
        &_paragraph {
          &_title {
            text-align: center;
          }
        }
      }
    }
  }
}

// DynamicCardsSlider
.news-slider {
  &_section {
    background-color: $background-light;
    overflow: hidden;
    padding-inline: $mobile-inline-spacing;
    &.dark {
      background-color: $background-dark;

      .news-slider_section_container {
        &_paragraph {
          &_title {
            color: $white;
          }
          &_description {
            color: $white;
          }
        }
      }
    }

    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 81.25rem;
      margin: auto;
      padding-block: $mobile-block-spacing;
      gap: 1rem;
      &_paragraph {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

        &_title {
          color: $title-color;
          text-align: start;
          font-size: $font-size-xxlarge;
          font-weight: 700;
          margin-block: unset;
        }

        &_description {
          color: $text-color;
          text-align: start !important;
          font-size: $font-size-large;
          font-weight: 400;
          margin-bottom: 1.5rem;
        }
      }

      &_button {
        margin-top: 2rem;
      }

      &_slider {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      &_container {
        padding-block: $desktop-block-spacing;

        &_paragraph {
          margin-bottom: 1rem;

          &_title {
            text-align: center;
            font-size: $font-size-xxlarge;
          }

          &_description {
            text-align: center !important;
            font-size: $font-size-xlarge;
            max-width: 80ch;
          }

          &_button {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}
