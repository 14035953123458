@use './mixins.module' as mixins;
$text-white: $white;
$title-color: $dark-blue;
$link-color: $dark-blue;
$text-color: $brownish-gray;
$background-dark: $dark-beige;
$breadcrumb-separator-color: hsl(0, 0%, 39%);
$breadcrumb-separator-color-inverted: hsl(0, 0%, 51%);
$header-height-mobile: 5.5rem;

// MainHero
.main-hero-section {
  .it-hero {
    &-wrapper {
      background-color: $body-bg;
      height: calc(100vh - $header-height-mobile);
      max-height: 50rem;
      padding-block: 2rem;
      align-items: flex-end;
      .img-responsive-wrapper .img-responsive .img-wrapper {
        height: 130%;
      }

      &.it-overlay.it-dark .img-responsive-wrapper:after {
        background: linear-gradient(
          180deg,
          rgba(51, 76, 102, 0.05) 0.52%,
          rgba(51, 76, 102, 0.8) 100%
        );
      }
      .main-hero {
        &_bg-img {
          margin-left: 0;
          margin-top: 10.625rem;
          object-fit: cover;
        }
        &_dl-svg {
          z-index: 1;
          margin-left: 1rem;
          position: absolute;
          margin-top: 0;
          width: 70%;
          max-width: 60%;
          top: -2%;
        }

        &_two_svg {
          scale: 0.8;
          position: relative;
          top: -2rem;
        }

        &_three_svg {
          display: none;
        }

        &_title {
          // display: none;
        }

        &_description {
          // @include mixins.text-shorten(8);
          &.display-5 {
            font-size: $font-size-large;
            font-family: 'Titillium Web', sans-serif;
            font-style: normal;
            font-weight: 600;
            line-height: 1.2rem;
          }
        }
      }
      .it-hero-text-wrapper {
        padding-inline: 2rem;
        z-index: 1;
      }

      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        min-height: 36rem;
        max-height: 42rem;
        padding-block: unset;
        &.it-overlay.it-dark .img-responsive-wrapper:after {
          margin-left: 14%;
          margin-top: 0;
        }

        .main-hero {
          &_bg-img {
            margin-left: 14%;
            margin-top: 0;
          }
          &_dl-svg {
            display: block;
            position: inherit;
            min-width: 40vw;
            z-index: 1;
            padding-left: 5.5%;
            margin-left: unset;
            align-self: center;
          }

          &_two_svg {
            position: relative;
            top: 3rem;
            left: -3rem;
            z-index: 1;
          }

          &_three_svg {
            display: block;
            position: relative;
            top: -4.7rem;
            left: 4rem;
            z-index: 1;
          }

          &_title {
            display: block;
            font-size: $font-size-xxxlarge;
          }

          &_description {
            &.display-4 {
              font-size: $font-size-large;
              font-family: 'Titillium Web', sans-serif;
              font-style: normal;
              font-weight: 600;
              line-height: 2rem;
            }
          }
        }

        .it-hero-text-wrapper {
          padding-block-start: 25% !important;
          padding-block-end: 10% !important;
          padding-inline-end: 10% !important;
          padding-inline-start: 20% !important;
        }
      }

      @media (min-width: #{map-get($grid-breakpoints,xl)}) {
        .main-hero {
          &_dl-svg {
            width: auto;
          }
          &_two_svg {
            scale: 1;
          }
          &_three_svg {
            position: relative;
            margin-inline-start: 3rem;
            top: -4.7rem;
            left: 10rem;
            scale: 1;
          }
        }
      }
    }
  }
}

// SideImageHero
.side-image-hero {
  &_section {
    .it-hero {
      &-wrapper {
        background-color: $body-bg;
        height: calc(100dvh - $header-height-mobile);
        max-height: 50rem;
        align-items: center;

        .img-wrapper {
          height: 100%;
        }
        .img-responsive-wrapper {
          position: relative;
        }
        &.it-overlay.it-dark .img-responsive-wrapper:after {
          background: linear-gradient(
            180deg,
            rgba(51, 76, 102, 0.05) 0.52%,
            rgba(51, 76, 102, 0.8) 100%
          );
        }
      }
    }
  }

  &_container-fluid {
    padding-inline: unset;
    padding-block-start: $mobile-block-spacing;
    height: 100%;
  }

  &_content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;

    &_breadcrumbs {
      &_wrapper {
        position: absolute;
        top: 1rem;
        left: 1rem;
        @media (min-width: #{map-get($grid-breakpoints, md)}) {
          left: 2rem;
        }
        @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
          margin-inline-start: calc(50% - 45rem);
        }
      }
    }

    .breadcrumb-container {
      .breadcrumb {
        &-item {
          > a {
            color: $link-color;
            font-weight: 700;
            font-size: $font-size-medium;
            line-height: 1.5rem;
          }

          &.active {
            > a {
              color: $text-color;
              font-weight: 400;
              text-decoration: unset;
            }
          }

          .separator {
            color: $breadcrumb-separator-color;
          }
        }
      }
    }
  }

  &_text-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding-inline: $mobile-inline-spacing;
    padding-block: 3rem $mobile-block-spacing;
    height: 50%;
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      padding-inline: $desktop-inline-spacing;
    }
    @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
      max-width: 45rem;
      margin-inline-start: calc(50% - 45rem);
    }
  }

  &_title {
    color: $primary-dark;
  }

  &_description {
    // @include mixins.text-shorten(8);
    &.display-5 {
      font-size: $font-size-large;
      font-family: 'Titillium Web', sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 1.8rem;
      color: $brownish-gray;
    }
  }

  &_img-column {
    height: 50%;
    aspect-ratio: 1/1;
    width: 100vw;
    padding-inline: unset;
    background: linear-gradient(
      180deg,
      rgba(51, 76, 102, 0) 0.52%,
      rgba(51, 76, 102, 0.7) 100%
    );
  }

  &_three-svg {
    z-index: 1;
    position: absolute;
    bottom: 5%;
    left: 5%;
  }

  &_img {
    aspect-ratio: 1/1;
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_description {
      &.display-4 {
        font-size: $font-size-large;
        font-family: 'Titillium Web', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8rem;
        color: $brownish-gray;
      }
    }
    &_container-fluid {
      padding-block-start: unset;
    }
    &_content-wrapper {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 1.8rem;
    }
    &_text-column {
      width: fit-content;
      height: 100%;
      padding-block: 6.5%;
    }

    &_img-column {
      max-width: 50vw;
      height: 100%;
      min-height: auto;
      position: relative;
      width: 50%;
      & > img {
        overflow: hidden;
      }
    }

    &_three-svg {
      z-index: 1;
      position: absolute;
      bottom: 5%;
      left: 12%;
    }

    &_section {
      .it-hero {
        &-wrapper {
          display: block;
          min-height: 31rem;
        }
      }
    }
  }

  &_wrapper {
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      min-height: 41rem;
    }
  }
}

// ContactsHero
.contacts {
  &_section {
    // margin-inline: auto;
    .it-hero {
      &-wrapper {
        // min-height: calc(105vh - $header-height-mobile);
        height: 20rem;
        align-items: center;
        // padding-block-end: 5rem;
        &.it-overlay.it-dark .img-responsive-wrapper:after {
          opacity: 0.699999988079071;
          background: var(--7070-light-blue, #4d9997);
        }

        .it-hero-text-wrapper {
          padding-block: $mobile-block-spacing !important;
          padding-inline: unset;
        }

        & .container {
          max-width: 87.5rem;
          padding-inline: 0;
          padding-inline: calc($mobile-inline-spacing + 0.75rem) !important;
          @media (min-width: #{map-get($grid-breakpoints, md)}) {
            padding-inline: calc($desktop-inline-spacing + 0.75rem) !important;
          }
          @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
            padding-inline: unset !important;
          }
          & .col {
            padding: unset;
          }
        }
      }
      p {
        font-family: 'Titillium Web', Geneva, Tahoma, sans-serif;
      }
    }

    &_svg-gradient {
      z-index: 1;
      position: absolute;
      top: 35%;
      right: 0;
    }

    &_svg-six-rectangles {
      position: absolute;
      z-index: 1;
      top: 8%;
      left: 70%;
      scale: 1.1;
      opacity: 1;
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        opacity: 0.2;
      }
    }

    &_svg-four-squares {
      display: none;
      position: absolute;
      z-index: 1;
      top: 21%;
      right: 7%;
      scale: 0.8;
      filter: opacity(0.5);
    }

    &_bg-img {
      object-position: 51%;
    }

    &_text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 2rem;
      padding-inline: 1rem;
      padding-block: 4rem;
      background-color: $background-light;
      height: 100%;

      &_title {
        color: $text-white;
        text-align: left;
        font-size: $font-size-xxlarge !important;
        font-weight: 700;
        margin-bottom: unset !important;
        max-width: 80ch;
      }
      &_description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        justify-content: center;
        max-width: 80ch;

        & span,
        p {
          font-weight: 400;
          margin-bottom: unset;
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      .it-hero {
        &-wrapper {
          min-height: 25rem;
        }
      }

      &_svg-gradient {
        display: none;
      }

      &_svg-six-rectangles {
        display: none;
      }

      &_svg-four-squares {
        display: initial;
      }

      &_text-wrapper {
        &_title {
          font-size: $font-size-xxlarge !important;
        }

        // &_description {
        //   & span,
        //   p {
        //     // @include mixins.text-shorten(8);
        //   }
        // }
      }
    }
  }
}

// BottomImageHero
.bottom-image-hero {
  &_section {
    display: flex;
    flex-direction: column;
    background-color: $background-dark;
    min-height: auto;
    &[data-has-image='false'] {
      min-height: fit-content;
    }
    .breadcrumb-container {
      padding: 1rem;
      width: 100%;
      margin-inline: auto;
      .breadcrumb {
        &-item {
          > a {
            color: $link-color;
            font-weight: 700;
            font-size: $font-size-medium;
            line-height: 1.5rem;
          }

          &.active {
            > a {
              color: $text-color;
              font-weight: 400;
              text-decoration: unset;
            }
          }

          .separator {
            color: $breadcrumb-separator-color;
          }
        }
      }
      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        padding: 2.25rem;
      }
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        width: 87.5rem;
        max-width: 87.5rem;
      }
    }

    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      min-height: auto;
    }
  }
  &_wrapper {
    display: grid;
    height: 100%;
    max-height: fit-content;
    grid-template-rows: 1fr 23.4375rem;
    &[data-has-image='false'] {
      grid-template-rows: 1fr;
    }

    &_paragraph-row {
      width: 100%;
      height: 100%;
      padding-block: $mobile-block-spacing;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 2rem;
      max-width: 87.5rem;
      margin: auto;
      padding-inline: $mobile-inline-spacing;
      @media (max-width: 87.59rem) {
        padding-inline: $desktop-inline-spacing;
      }
      @media (min-width: 1425px) {
        padding-inline: unset;
      }

      &_title {
        color: $title-color;
        text-align: left;
        font-size: $font-size-xxlarge;
        line-height: 3rem;
        margin-bottom: unset;
      }

      .richtext-default {
        font-size: $font-size-large;
        & > p {
          font-size: $font-size-large;
        }
      }
    }
    &_image-row {
      width: 100dvw;
      height: 100%;
      position: relative;

      & > img {
        object-fit: cover;
      }
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      min-height: unset;
      grid-template-rows: 1fr 38.625rem;
      &[data-has-image='false'] {
        grid-template-rows: 1fr;
      }

      &_paragraph-row {
        padding-block-start: $desktop-block-spacing;
        &_title {
          font-size: $font-size-xxlarge;
          line-height: 3.5rem;
        }

        .richtext-default {
          font-size: $font-size-large;
        }
      }

      &_image-row {
        min-height: 30rem;
      }
    }
  }
}

.full-overlay-hero {
  &_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .it-hero {
      &-wrapper {
        align-items: center;
        height: calc(100vh - $header-height-mobile);
        max-height: 50rem;
        width: 100vw;
        & > div.container {
          max-width: unset;
          padding: $mobile-block-spacing $mobile-inline-spacing;
          & > div.row {
            & > div.col {
              padding-inline: unset;
            }
          }
        }

        .it-hero-text-wrapper {
          padding: unset !important;
          padding-inline: $mobile-inline-spacing !important;
          margin: auto;
          padding-block: $mobile-block-spacing !important;
        }
      }
    }

    &_text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &_breadcrumbs {
        &_wrapper {
          &_item {
            > a {
              color: $white !important;
              font-size: $font-size-medium;
              font-weight: 700 !important;
            }
            > span {
              &.separator {
                color: $breadcrumb-separator-color-inverted !important;
                font-size: $font-size-medium;
              }
            }

            &.active {
              > a {
                text-decoration: none !important;
                font-size: $font-size-medium;
                font-weight: 400 !important;
              }
            }
          }
        }
      }

      &_title {
        color: $text-white;
        text-align: left;
        font-size: $font-size-xxlarge;
        line-height: 3rem;
        font-weight: 700;
        margin-bottom: unset !important;
      }

      &_description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        font-size: $font-size-large !important;
        font-family: 'Titillium Web', Geneva, Tahoma, sans-serif !important;
        font-weight: 400;
        margin-bottom: unset !important;
        // @include mixins.text-shorten(8);
      }
      &_published-at {
        > p {
          font-size: $font-size-regular;
          font-weight: 400;
          color: $text-white;
          margin-bottom: unset;
          font-family: 'Titillium Web', Geneva, Tahoma, sans-serif !important;
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      .it-hero {
        &-wrapper {
          min-height: fit-content;

          & > div.container {
            margin-inline: unset !important;
            padding: unset !important;
          }
          .it-hero-text-wrapper {
            width: 100%;
            padding: unset !important;
            padding-inline: $desktop-inline-spacing !important;
            padding-block: $desktop-block-spacing !important;
          }
        }
      }

      &_text-wrapper {
        &_breadcrumbs_wrapper {
          margin-bottom: 2rem;
        }
        &_title {
          max-width: 60%;
          font-size: $font-size-xxxlarge;
          line-height: 3.5rem;
        }
        &_description {
          max-width: 85ch;
          font-size: $font-size-large !important;
          line-height: 2rem;
          p {
            font-size: $font-size-large !important;
            font-family: 'Titillium Web', sans-serif !important;
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,xxl)}) {
    &_section {
      .it-hero {
        &-wrapper {
          .it-hero-text-wrapper {
            padding-inline: 1rem !important;
          }
        }
      }
      &_text-wrapper {
        left: calc((100vw - 87.5rem) / 2);
        margin-inline: auto;
        padding-inline: unset !important;
      }
    }
  }
}
