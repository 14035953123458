$border-color: $teal-dark;
$border-color-darker: $teal-darker;

$background-dark: $teal-dark;
$background-darker: $teal-darker;
$background-disabled: $light-gray;
$background-white: $white;

$link-color: $teal-medium-1;
$link-focus: $teal-medium-2;
$link-hover: $teal-dark;
$link-active: $teal-darker;
$link-disabled: $light-gray;

$inverted-color: $dark-blue;

.btn:not(:disabled):not(.disabled):active:focus,
.toggles
  label
  input[type='checkbox']:focus
  + .btn.lever:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + .btn.lever:not(:disabled):not(.disabled):active,
.form-check
  [type='checkbox']:focus
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  [type='radio']:focus
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled):active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active:focus,
.toggles
  label
  input[type='checkbox']:focus
  + .btn.lever:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + .btn.lever:not(:disabled):not(.disabled).active,
.form-check
  [type='checkbox']:focus
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  [type='radio']:focus
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.btn:not(:disabled):not(.disabled).active,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled).active,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.btn:not(:disabled):not(.disabled).active {
  box-shadow: unset;
}

.btn {
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  &:focus {
    box-shadow: unset;
  }
  &.btn-primary {
    background-color: $primary;
    border: 2px solid;
    border-color: $primary;
    color: $white;
    width: fit-content;

    &:focus {
      border-color: $border-color;
    }

    &:hover {
      background-color: $background-dark;
      border-color: $border-color;
      scale: 1.05;
    }

    &:active {
      background-color: $background-darker;
      border-color: $border-color-darker;
    }

    &:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      background-color: $background-darker;
      border-color: $border-color-darker;
    }

    &.disabled {
      background-color: $background-disabled;
      border-color: $background-disabled;
      cursor: not-allowed;
      pointer-events: unset;
    }
  }

  &.btn-secondary {
    border-radius: 0.25rem;
    border: 2px solid $teal;
    background-color: transparent;
    padding: 0.75rem 1.5rem;
    color: $teal;
    text-align: center;
    font-weight: 700;
    font-size: $font-size-regular;
    line-height: 1.5rem;
    scale: 0.95;

    &:hover {
      border-color: $teal-dark;
      color: $teal-dark;
      scale: 1;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      font-size: $font-size-medium;
      line-height: 1.75rem;
    }
  }

  &.btn-link {
    color: $link-color;
    font-size: $font-size-medium;
    line-height: 1.75rem;
    padding: unset;

    &:focus {
      color: $link-focus;
    }

    &:hover {
      color: $link-hover;
      text-decoration: underline;
    }

    &:active {
      color: $link-active;
    }

    &:disabled {
      color: $link-disabled;
      cursor: not-allowed;
      pointer-events: unset;
    }
  }

  &.btn-inverted {
    border-radius: 0.25rem;
    background-color: $background-white;
    color: $inverted-color;
    font-size: $font-size-medium;

    &:hover {
      filter: brightness(0.9);
      scale: 1.05;
    }
  }

  &.btn-outline {
    border-radius: 0.25rem;
    border: 2px solid $white;
    background-color: transparent;
    padding: 0.75rem 1.5rem;
    color: $white;
    text-align: center;
    font-weight: 700;
    font-size: $font-size-regular;
    line-height: 1.5rem;
    text-decoration: unset;
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      font-size: $font-size-medium;
      line-height: 1.75rem;
    }
    &:hover {
      scale: 1.05;
      filter: brightness(0.9);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
  &.btn-outlinedark {
    &.btn-secondary {
      // background-color: $brownish-gray;
      &.dropdown-toggle:focus {
        color: $white;
        background-color: $brownish-gray;
        border-color: $brownish-gray;
        box-shadow: unset;
      }
      &:active {
        color: $white;
        background-color: $brownish-gray;
        border-color: $brownish-gray;
        box-shadow: unset;
      }
    }
    border-radius: 0.25rem;
    box-shadow: unset;
    border: 2px solid $gray;
    background-color: transparent;
    padding: 0.75rem 1.5rem;
    color: $gray;
    text-align: center;
    font-weight: 700;
    font-size: $font-size-regular;
    line-height: 1.5rem;
    &:focus .btn-link:focus {
      text-decoration: unset;
      color: $white;
      background-color: $brownish-gray;
      border-color: $brownish-gray;
    }

    &:hover {
      color: $white;
      background-color: $brownish-gray;
      border-color: $brownish-gray;
    }

    &:active {
      color: $white;
      background-color: $brownish-gray;
      border-color: $brownish-gray;
      box-shadow: unset;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      font-size: $font-size-medium;
      line-height: 1.75rem;
    }
  }
}
