@use './mixins.module' as mixins;

$header-slim-bg-color: $primary-dark;
$header-center-bg-color: $primary-light;

.it-header-wrapper {
  & * {
    transition: all 0.3s;
  }
  & > .it-nav-wrapper > div.it-header-sticky.it-header-navbar-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    & > div > div > div > nav > div {
      width: 100%;
    }
  }
  & > div.slim-wrap {
    &.it-header-sticky {
      z-index: 9999;
      & ~ div.it-nav-wrapper {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 9999;
      }
    }
  }
  & div.it-nav-wrapper {
    .it-header-navbar-wrapper nav {
      padding-inline: unset;
    }
    & .it-header-normal {
      position: static;
    }
  }
}

// slim header - is the top bar
.it-header-slim-wrapper {
  background-color: $header-slim-bg-color;
  height: 3rem;
  display: flex;
  padding-inline: 0;
  width: 100%;
  align-items: center;

  & > div.container {
    width: 100%;
    padding: 0;
    max-width: 87.5rem;
    margin-inline: auto;
    padding-inline: 1rem;
    @media (min-width: #{map-get($grid-breakpoints,md)}) and (max-width: #{map-get($grid-breakpoints,xxl)}) {
      padding-inline: 2rem;
    }
    & > div.row {
      width: 100%;
      max-width: unset;
      margin: unset;
      padding: unset;
      & > div.col {
        width: 100%;
        max-width: unset;
        margin: unset;
        padding: unset;
        & > div.it-header-slim-wrapper-content {
          padding: unset;
          a {
            padding: 0;
            font-size: clamp(
              $font-size-small,
              $font-size-regular,
              $font-size-medium
            ) !important;
          }
          > div.container {
            margin: auto;
          }
          height: 100%;
        }
      }
    }
  }
}

// header center - is the main header
.it-header-center-wrapper {
  height: 5.5rem;
  padding-inline: $desktop-inline-spacing;
  background-color: $header-center-bg-color;
  & > div.container {
    padding-inline: 1.5rem;
    margin-inline: auto;
    @media (min-width: #{map-get($grid-breakpoints,md)}) and (max-width: #{map-get($grid-breakpoints,xxl)}) {
      padding-right: 2.5rem;
    }
  }
  & .col {
    padding-inline: 0;
  }
  & .it-header-center-content-wrapper {
    padding-inline: unset;
    & .it-search-wrapper {
      margin-inline-start: 1.5rem;
      & > a {
        &:hover {
          scale: 1.05;
          filter: drop-shadow(0 0 0.25rem $primary);
        }
      }
    }
    & .it-brand-wrapper {
      & > a {
        padding-bottom: 4px;
        img.icon {
          object-fit: cover;
        }
        & .it-brand-text {
          display: flex;
          flex-direction: column;
          min-height: 60px;
          position: relative;
          padding-inline-end: unset;
          justify-content: end;
          & p {
            margin-block: auto;
            text-overflow: ellipsis;
            @media (min-width: #{map-get($grid-breakpoints,md)}) {
              margin-block: auto;
            }
          }
          & span {
            transition: all 0.3s ease-in-out;
            font-size: clamp(
              $font-size-regular,
              $font-size-medium,
              $font-size-large
            ) !important;
            @include mixins.text-shorten(1);
            &:hover {
              position: absolute;
              width: clamp(600px, calc(100% - 3rem), 100%);
              background-color: $primary;
              box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
            }
            @media (max-width: #{map-get($grid-breakpoints,md)}) {
              display: none !important;
            }
          }
        }
      }
    }
    &.it-header-center-content-wrapper .it-right-zone .it-socials {
      & > ul > li {
        &:hover {
          scale: 1.25;
          filter: drop-shadow(0 0 0.25rem $primary);
        }
      }
      span {
        width: 4.25rem;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    > div.container {
      max-width: 87.5rem;
    }
  }
}
// header navbar - is the bottom header with the menu
.it-header-navbar-wrapper {
  padding-inline-start: $desktop-inline-spacing;
  transition: all 0.3s;
  & button {
    &:hover {
      scale: 1.1;
    }
  }
}
@media (min-width: #{map-get($grid-breakpoints,lg)}) {
  .it-header-navbar-wrapper {
    background-color: $header-center-bg-color;
    > div.container {
      max-width: 90.5rem;
      margin-inline-start: calc(auto - 1.125rem);
      padding: unset;
      & div.col {
        padding: unset;
      }
    }
  }
}

// header sidebar - is the left sidebar on mobile
.navbar .navbar-collapsable {
  z-index: 10;
  .menu-wrapper {
    &_logo {
      margin-block: auto;
      margin-inline-end: 1rem;
    }
    & > ul {
      & > div {
        // margin-inline-start: calc(100dvw / 6);
        margin-inline-start: 1rem;
        width: auto;
        // aspect-ratio: 1;
        &:hover {
          filter: brightness(1.2);
          scale: 1.1;
        }
        svg {
          fill: $primary;
          background-color: white;
          padding: 0.25rem;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
        }
      }
    }
    background-color: $header-center-bg-color;
  }
  .navbar-nav {
    width: 100%;
    & > div:last-child {
      margin-inline: auto $desktop-inline-spacing;
    }
    & li {
      & a.nav-link {
        color: $white;
        text-wrap: nowrap;
        &:hover {
          scale: 1.025;
        }
      }
    }
  }
}
.sidebar-icon-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  fill: $white;
  z-index: 11;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    scale: 1.1;
    transform: rotate(180deg);
  }
}
