// Input
.form-group,
.form-group input,
.form-group optgroup,
.form-group textarea {
  color: $blueish-gray;
  margin: unset;
  display: flex;
  min-width: 18rem;
  max-width: 46rem;
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    min-width: 29.5rem;
  }
}
.form-control:focus,
.toggles label input[type='checkbox']:focus + .form-control.lever,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label.form-control,
.toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + .form-control.lever,
.form-check [type='checkbox']:focus + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='checkbox']
  + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='checkbox']
  + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='checkbox'].lever
  + label.form-control,
.form-check [type='radio']:focus + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='checkbox'].lever
  + label[type='radio']
  + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + [type='radio'].lever
  + label[type='radio']
  + label.form-control,
.form-check
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.form-control,
.toggles
  label
  .form-check
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + [type='radio'].lever
  + label.form-control {
  color: $blueish-gray;
}

.input-group {
  &-prepend {
    margin-block-start: auto;
  }
  &-text {
    border: none;
    border-bottom: 1px solid $blueish-gray;
    background-color: transparent;
  }
}
input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  min-height: 2rem;
  border-bottom: 1px solid $blueish-gray;
  padding: 0.5rem;
  font-size: $font-size-regular;
  line-height: 2rem;
  font-weight: 400;

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    min-height: 3rem;
    font-size: $font-size-xlarge;
  }

  &::placeholder {
    font-size: $font-size-regular;
    line-height: 2rem;
    font-weight: 100;

    @media (min-width: #{map-get($grid-breakpoints,md)}) {
      font-size: $font-size-xlarge;
      line-height: 2rem;
    }
  }
}
