// Dropdown
.custom-dropdown {
  &_toggle {
    > svg.icon {
      margin-inline-start: 0.5rem;
      transition: all 0.3s ease-in-out;
      fill: $brownish-gray !important;
    }
    &[aria-expanded='true'] {
      .custom-dropdown_toggle_chevron {
        transform: rotate(180deg);
        color: $white;
      }
    }
    &_chevron {
      margin-inline-start: 0.5rem;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      > svg {
        fill: $white !important;
      }
    }
  }
}
.dropdown-menu {
  .link-list-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    li a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: $font-size-medium;
      line-height: 1.75rem;
      font-weight: 400;
      color: $brownish-gray;
      padding: unset;

      > svg {
        width: 1.25rem;
        margin: unset;
      }

      > span {
        color: inherit;
        width: 80%;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
// By default, Dropdown (from DesignReactKit) uses btn-secondary. We override it here.
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: $brownish-gray;
  border-color: $brownish-gray;
  box-shadow: unset;
}
