.pixel-decorator {
  position: relative;
  width: 100%;
  height: 100%;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url('../images/pixel.png');
  //   background-size: 100% 100%;
  //   opacity: 0.1;
  // }

  &_svg {
    position: absolute;
    top: 0;
    left: 0;
    width: clamp(100px, calc(100% / 2), 150px);
    height: clamp(100px, calc(100% / 2), 250px);
    z-index: 3;
    animation: fadeIn 3s ease-in-out;
    transition: all 0.3s ease-in-out;
    filter: drop-shadow(15px 5px 10px rgba(0, 0, 0, 0.5));

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      width: clamp(100px, calc(100% / 2), 200px);
      height: clamp(100px, calc(100% / 2), 200px);
    }

    &.mobile-hidden {
      display: none;
    }

    &.a-shape {
      animation: slideLeftToRight 1s ease-in-out;
      &:hover {
        transform: translateX(50px);
      }
    }

    &.b-shape {
      animation: slideLeftToRight 1s ease-in-out;
      &:hover {
        transform: translateX(30px);
      }
    }

    &.c-shape {
      width: clamp(50px, calc(100% / 4), 125px);
      height: clamp(50px, calc(100% / 4), 125px);
      animation: rotate90 1s ease-in-out;
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
}
