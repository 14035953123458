$title-color: $dark-blue;
$text-color: $brownish-gray;
$background-dark: $dark-beige;
$background-dark-inverse: $dark-blue;

// SmallParagraph
.small-paragraph {
  &_section {
    margin: auto;
    background-color: $body-bg;
    padding-inline: $mobile-inline-spacing;
    padding-block: $mobile-block-spacing;
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      padding-block: $desktop-inline-spacing;
      padding-inline: $desktop-inline-spacing;
    }

    &_container {
      display: block;
      width: 100%;
      max-width: 85.25rem;
      margin: 0 auto;
    }
  }

  &_text-wrapper {
    max-width: 87.5rem;
    gap: 1.8rem;
    margin: unset;
    & > span {
      padding: unset;
      & > p {
        // max-width: 85ch;
        margin: auto;
      }
    }
    > a,
    button {
      margin: auto;
    }
  }

  &_title {
    text-align: center;
    &.text-left {
      text-align: left;
    }
    &.text-right {
      text-align: right;
    }

    &.text-center {
      text-align: center;
    }
    padding: unset;
    color: $title-color;
    font-size: $font-size-xxlarge;

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      font-size: $font-size-xxlarge;
    }
  }
}

// FullImageParagraph
.full-image-paragraph {
  &_section {
    background-color: $background-dark;
  }

  &_wrapper {
    display: grid;
    grid-template-rows: minmax(fit-content, 30rem) 12rem;

    &_paragraph-row {
      width: 100%;
      height: 100%;
      padding-inline: $mobile-inline-spacing;
      padding-block: $mobile-block-spacing;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 1.8rem;
      max-width: 100%;

      &_title {
        color: $title-color;
        text-align: center;
        font-size: $font-size-xxlarge;
      }

      .richtext-default {
        font-size: $font-size-large;
      }
    }
    &_image-row {
      width: 100%;
      height: 100%;
      min-height: 12rem;
      position: relative;

      & > img {
        object-fit: cover;
      }
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      grid-template-rows: minmax(fit-content, 12rem) 30rem;

      &_paragraph-row {
        padding-block: $desktop-block-spacing;
        max-width: 100ch;
        justify-self: center;
      }

      &_image-row {
        min-height: 30rem;
      }
    }
  }
}

// DarkParagraphWithImage
.dark-paragraph-image {
  &_section {
    background-color: $background-dark-inverse;
    padding-block: 2rem 0rem;

    &_container {
      display: flex;
      flex-direction: column;
      gap: 1.375rem;

      &_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-block: 1.5rem;
        padding-inline: 1rem;
        gap: 2rem;

        &_title {
          font-size: $font-size-xxlarge;
          line-height: 3rem;
          font-weight: 700;
          color: $white;
          margin: unset;
        }

        &_description {
          font-size: $font-size-medium;
          line-height: 2rem;
          font-weight: 400;
          color: $white;
          margin: unset;
        }
      }

      &_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &_wrapper {
          position: relative;
          overflow: hidden;
          min-width: 100dvw;
          max-height: 25rem;
          aspect-ratio: 1;
          > img {
            object-fit: cover;
          }
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-block: unset;

      &_container {
        &_text {
          padding-block: 3rem;
          justify-content: center;
          padding-inline: 2.5rem;
        }

        &_image {
          flex: 1;
          max-height: 45rem;
          overflow: hidden;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &_section {
      &_container {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 3rem;
        &_text {
          max-width: 45%;
        }
        &_image {
          max-height: 68.75rem;
          &_wrapper {
            aspect-ratio: unset;
            min-height: 100dvh;
            max-width: 50dvw;
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        &_image {
          max-height: 51.25rem;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
    &_section {
      &_container {
        &_text {
          max-width: 45rem;
          &.right {
            margin-inline-start: calc(50vw - 45rem);
          }
          &.left {
            margin-inline-end: calc(50vw - 45rem);
          }
        }
        &_image {
          &_wrapper {
            max-width: unset;
            max-height: 45rem;
          }
        }
      }
    }
  }
}

// ParagraphCard
.paragraph-card {
  &_section {
    padding: $mobile-block-spacing $mobile-inline-spacing;
    &.darkBg {
      background-color: $background-dark;
    }
    &_container {
      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;
        padding-block: 2.5rem 2rem;

        &_title {
          color: $title-color;
          text-align: left;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          margin-bottom: unset;
        }
        &_description,
        .richtext-default.text-left {
          color: $text-color;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
        }
      }
      &_cards {
        gap: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        display: flex;
        flex-direction: column;
        max-width: 85.25rem;
        margin: 0 auto;
        gap: 3rem;

        &_paragraph {
          @media (min-width: #{map-get($grid-breakpoints, xl)}) {
            max-width: 45%;
          }
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }
          &_description,
          .richtext-default.text-left {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }

        &_cards {
          flex-flow: row wrap;
          column-gap: 1.75rem;
          row-gap: 1.5rem;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }
}

// ParagraphWithImage
.paragraph-with-image {
  &_section {
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;
    &_container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      max-width: 87.5rem;
      margin: 0 auto;
      &_text {
        padding-block-end: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        &_title {
          color: $title-color;
          text-align: center;
          font-size: $font-size-xxlarge;
          line-height: 3rem;
          font-weight: 700;
          letter-spacing: -1px;
          margin-bottom: unset;
          text-align: left;
        }
        &_description {
          color: $text-color;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
          text-align: left;
          & > p {
            font-size: $font-size-large;
          }
        }
      }
      &_image {
        position: relative;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        width: calc(100vw - 2rem);
        aspect-ratio: 1;
        > img {
          object-fit: cover;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
      &_container {
        &_text {
          width: 100%;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3.5rem;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }
        &_image {
          width: 28rem;
          height: 33rem;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &_section {
      &_container {
        flex-direction: row;
        justify-content: space-between;
        &_text {
          max-width: clamp(50ch, 100ch, 500ch);
        }
        &_image {
          width: 28rem;
          height: 34.125rem;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        &_image {
          width: 34.25rem;
          height: 40.375rem;
        }
      }
    }
  }
}
