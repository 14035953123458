#nprogress {
  /* Make clicks pass-through */
  pointer-events: none;
  & .bar {
    background: $primary;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  & .per {
    display: block;
    position: absolute;
    right: 0px;
    width: 6.25rem;
    height: 100%;
    box-shadow: 0 0 10px $primary 0 0 5px $primary;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -0.25rem);
    -ms-transform: rotate(3deg) translate(0px, -0.25rem);
    transform: rotate(3deg) translate(0px, -0.25rem);
  }
  /* Remove these to get rid of the spinner */
  & .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 1rem;
    right: 1rem;
  }

  & .spinner-icon {
    width: 1.125rem;
    height: 1.125rem;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: $primary;
    border-left-color: $primary;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;

  & #nprogress .spinner,
  & #nprogress .bar {
    position: absolute;
  }
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
