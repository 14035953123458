$background-dark: $dark-beige;
$background-light: $light-beige;
$title-color: $dark-blue;
$text-color-logos: $dark-blue;
$text-color: $brownish-gray;
$border-color: $brownish-gray;

// AvatarImageList
.avatar-img-list {
  &_section-wrapper {
    background-color: $background-light;
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;

    &_paragraph {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-width: 87.5rem;
      margin: auto;
      padding-block: 1.5rem 2.5rem;

      &_title {
        color: $title-color;
        text-align: left;
        font-size: $font-size-xxlarge;
        font-weight: 700;
        margin-bottom: unset;
      }

      &_description {
        color: $text-color;
        text-align: left;
        font-size: $font-size-large;
        font-weight: 400;
        margin-bottom: unset;
      }

      & a.btn-primary {
        margin-block-start: 2.5rem;
      }
    }

    &_image-list {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        &_sub-section-title {
          color: $title-color;
          text-align: left;
          font-size: $font-size-xlarge;
          font-weight: 600;
          margin-bottom: unset;
          line-height: 2rem;
        }

        &_sub-section-description {
          color: $text-color;
          text-align: left;
          font-size: $font-size-regular;
          font-weight: 400;
          line-height: 1.5rem;
          margin: unset;
          margin-block-start: 1.5rem;
          @media (min-width: #{map-get($grid-breakpoints,md)}) {
            font-size: $font-size-large;
          }
        }
      }
    }

    @media (min-width: #{map-get($grid-breakpoints,md)}) {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
      &_paragraph {
        // max-width: 50%;
      }

      &_image-list {
        display: flex;
        flex-flow: column wrap;
        align-content: space-between;
        height: 40.5rem;
        max-width: 87.5rem;
        margin: auto;

        &::before,
        &::after {
          content: '';
          flex-basis: 100%;
          width: 0;
          order: 2;
        }

        &_wrapper {
          width: 48%;
          min-height: 18rem;
          flex: 1;
          &:nth-child(2n + 1) {
            order: 1;
          }
          &:nth-child(2n + 2) {
            order: 2;
          }
        }
      }
    }
  }
}

// LogosImageList
.logos-img-list {
  &_section-wrapper {
    background-color: $background-light;
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;

    &_paragraph {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-block: 1.5rem;
      max-width: 87.5rem;
      margin: auto;

      &_title {
        color: $title-color;
        text-align: left;
        font-size: $font-size-xxlarge;
        font-weight: 700;
        margin-bottom: unset;
      }

      &_description {
        color: $text-color;
        text-align: left;
        font-size: $font-size-large;
        line-height: 2rem;
        font-weight: 400;
        margin-bottom: unset;
      }
    }

    &_image-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &_item {
        &_wrapper {
          display: flex;
          flex-direction: column;
        }

        &_img-wrapper {
          display: flex;
          position: relative;
          min-height: 5.5rem;
          width: 5.5rem;
          aspect-ratio: 1/1;
          margin-bottom: 1rem;
        }

        &_text-wrapper {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $border-color;
          padding-block-end: 2rem;

          &_title {
            color: $title-color;
            text-align: left;
            line-height: 1.5rem;
            font-size: $font-size-large;
            font-weight: 600;
            margin-bottom: 0.25rem;
          }

          &_description {
            color: $text-color-logos;
            text-align: left;
            line-height: 1.5rem;
            font-size: $font-size-regular;
            font-weight: 400;
            margin-bottom: 1rem;
          }

          &_cta.btn-link {
            color: $title-color;
            font-weight: 700;
            font-size: $font-size-medium;
            line-height: 1.75rem;
            text-align: left;
          }
        }
      }
    }

    @media (min-width: #{map-get($grid-breakpoints,md)}) {
      padding-inline: $desktop-inline-spacing;
      padding-block-start: $desktop-block-spacing;

      &_paragraph {
        &_title {
          font-size: $font-size-xxlarge;
          font-weight: 700;
        }

        &_description {
          font-size: $font-size-xlarge;
          line-height: 2.5rem;
        }
      }

      &_image-list {
        display: flex;
        flex-flow: column wrap;
        align-content: flex-start;
        @media (min-width: #{map-get($grid-breakpoints,lg)}) {
          height: 29rem;
          max-width: 87.5rem;
          margin: auto;
        }

        &_item {
          &_wrapper {
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
            border-bottom: 1px solid $border-color;
            min-width: 50%;
            width: 100%;
            @media (min-width: #{map-get($grid-breakpoints,lg)}) {
              width: 45%;
              height: 45%;
            }
          }

          &_img-wrapper {
            height: 9.25rem;
            width: 9.25rem;
            aspect-ratio: 1/1;
          }

          &_text-wrapper {
            border: unset;

            &_title {
              font-size: $font-size-xlarge;
              line-height: 2rem;
            }

            &_description {
              font-size: $font-size-large;
              line-height: 1.75rem;
            }

            &_cta.btn-link {
              font-size: $font-size-medium;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }
}
