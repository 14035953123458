$border-color: $brownish-gray;
$text-color: $dark-blue;

.avatar {
  &.size-lg {
    width: 2.5rem;
    height: 2.5rem;
  }
  &_wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0.5rem;

    &_text {
      color: $text-color;
      font-size: $font-size-regular;
      font-weight: 600;
      line-height: 1.5rem;
    }

    &--border-bottom {
      border-bottom: 1px solid $border-color;
    }

    &_image {
      max-width: 2.5rem;
      max-height: 2.5rem;
    }

    .avatar-wrapper {
      display: flex;
      align-self: center;
    }
  }
}
