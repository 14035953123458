$title-color: $dark-blue;
$text-color: $brownish-gray;
$accordion-border: hsl(47, 6%, 20%);

//Accordion
.collapse {
  &-div {
    border-bottom: 1px solid $accordion-border;
  }
  &-header {
    .accordion {
      &_header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        column-gap: 1.5rem;

        color: $title-color;
        font-size: $font-size-regular;
        font-weight: 700;
        line-height: 1.5rem;

        @media (min-width: #{map-get($grid-breakpoints,md)}) {
          font-size: $font-size-medium;
          line-height: 1.75rem;
        }
        &_chevron {
          transition: transform 0.3s ease-in-out;
          color: $brownish-gray;
        }
      }
    }

    *[data-toggle='collapse'] {
      transition: all 0.3s ease-in-out;
      border-top: 1px solid $accordion-border;
      padding: 1rem 1.5rem;

      &:before {
        content: none;
      }
      &[aria-expanded='false'] {
        color: $title-color;
      }
      &[aria-expanded='true'] {
        .accordion_header_chevron {
          transform: rotate(180deg);
          color: $white;
        }
      }
    }
  }
  &-body {
    padding-inline: 1.5rem;
    padding-block: 0.5rem 2.5rem;

    .accordion_body_text {
      font-size: $font-size-regular;
      font-weight: 400;
      line-height: 1.5rem;
      margin-bottom: unset;
      @media (min-width: #{map-get($grid-breakpoints,md)}) {
        font-size: $font-size-medium;
        line-height: 1.75rem;
      }
    }
  }
}
