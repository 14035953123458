$title-color: $dark-blue;
$text-color: $brownish-gray;
$border-color: $brownish-gray;

// ContentParagraphList
.content-paragraph-list {
  &_section {
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 2rem;
      gap: 2rem;
      width: 100%;
      max-width: 87.5rem;
      margin-inline: auto;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;

        &_title {
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          color: $title-color;
          margin: unset;
        }

        &_description {
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          color: $text-color;
          margin: unset;
        }
      }

      &_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;

        &_item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          gap: 2rem;

          &_title {
            font-size: $font-size-xlarge;
            line-height: 2rem;
            font-weight: 700;
            color: $title-color;
            margin: unset;
          }

          &_content {
            display: flex;
            flex-direction: column;
            width: 100%;

            &_item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              border-bottom: 1px solid $border-color;
              padding-block: 1.5rem;
              gap: 0.75rem;

              &_title {
                font-size: $font-size-regular;
                line-height: 1.5rem;
                font-weight: 600;
                color: $title-color;
                margin: unset;
              }

              &_description {
                font-size: $font-size-regular;
                line-height: 1.5rem;
                font-weight: 400;
                color: $text-color;
                margin: unset;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-block: $desktop-block-spacing;
      padding-inline: $desktop-inline-spacing;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &_section {
      &_container {
        gap: 4rem;
        align-items: flex-start;
        &_paragraph {
          // max-width: 50%;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
            font-weight: 700;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
            font-weight: 400;
          }
        }

        &_list {
          flex-direction: row;
          align-items: baseline;

          &_item {
            width: 47%;
            &_content {
              flex-flow: column wrap;
              max-height: 45rem;
              column-gap: 2rem;
              &_item {
                width: 100%;

                &_title {
                  font-size: $font-size-medium;
                  line-height: 1.5rem;
                }

                &_description {
                  font-size: $font-size-medium;
                  line-height: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ContentCardsList
.content-cards-list {
  &_section {
    background-color: $background-dark;
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;

    &_container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-width: 87.5rem;
      margin: auto;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-block: 2.5rem 1.5rem;
        gap: 2rem;

        &_title {
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          color: $title-color;
          margin: unset;
        }

        &_description {
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          color: $text-color;
          margin: unset;
        }
      }

      &_list {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-block: $desktop-block-spacing;
      padding-inline: $desktop-inline-spacing;

      &_container {
        &_paragraph {
          // max-width: 50%;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
            font-weight: 700;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}

// HighlightedCardList
.highlighted-card-list {
  &_section {
    padding: $mobile-block-spacing $mobile-inline-spacing;
    &.darkBg {
      background-color: $background-dark;
    }

    &_container {
      display: flex;
      flex-direction: column;
      max-width: 85.25rem;
      margin: 0 auto;
      gap: 1rem;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &_title {
          color: $title-color;
          text-align: left;

          font-size: $font-size-xlarge !important;
          line-height: 3rem;
        }
      }

      &_cards {
        background-color: $dark-gray;
        padding: $mobile-block-spacing;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &_cards_header {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_title {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          h3 {
            padding-top: 0.25rem;
            margin: 0;
            color: white;
            font-size: $font-size-large !important;
            line-height: 1 !important;
            font-weight: 600;
          }
        }

        &_abstract {
          color: white;
          font-size: $font-size-large;
          line-height: 1;
          font-weight: 600;
        }
      }

      &_cards_main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
      }

      &_cards_show {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: #{map-get($grid-breakpoints, sm)}) {
          display: none;
        }

        button {
          background-color: transparent;
          border: none;
          color: white;
          font-size: $font-size-small !important;
          text-transform: uppercase;
          line-height: 1;
          font-weight: 600;

          &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 0;
            margin-left: 0.5rem;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid white;
          }
        }

        &.opened {
          button {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    &_section {
      &_container {
        &_paragraph {
          @media (min-width: #{map-get($grid-breakpoints, xl)}) {
            max-width: 45%;
          }
        }
      }
    }
  }
}

.progress-cards-list {
  &_section {
    padding: $mobile-block-spacing $mobile-inline-spacing;

    &_container {
      display: flex;
      flex-direction: column;
      max-width: 85.25rem;
      margin: 0 auto;
      gap: 1rem;

      &_paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &_title {
          color: $title-color;
          text-align: left;

          font-size: $font-size-xlarge !important;
          line-height: 3rem;
        }
      }

      &_cards {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &_cards_header {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_title {
          display: flex;
          align-items: flex-start;
          gap: 1rem;

          h3 {
            padding-top: 0.25rem;
            margin: 0;
            color: white;
            font-size: $font-size-large !important;
            line-height: 1 !important;
            font-weight: 600;
          }
        }

        &_abstract {
          color: white;
          font-size: $font-size-large;
          line-height: 1;
          font-weight: 600;
        }
      }

      &_cards_main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
    }
  }
}
